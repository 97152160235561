import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import ScrollingBanner from "../components/heroBanner"
import SplitSection from "../components/splitSection"
import DesignSection from "../components/designSection"
import Carousel from "../components/carousel"
import Spacer from "../components/spacer"
import Magazine from "../components/magazine"
import VideoPlayer from "../components/videoPlayer"
import ContactBanner from "../components/contactBanner"
import { graphql } from "gatsby"
import Gallery from "../components/gallery"
import Map from "../components/map"
import Team from "../components/team"
import Updates from "../components/updates"
import Footer from "../components/footer"

const IndexPage = ({ data: { page }, location }) => {
	const contactInfo = { email: page.email, insta: page.instagram, facebook: page.facebook }
	return (
		<Layout>
			<SEO title='Tapestry' />
			<Header location={location} />
			<ScrollingBanner caption={page.caption} mobileImage={page.mobileIntroductionImage} image={page.introductionImage} />
			<Spacer height='double ' className='m-hide' />
			<Spacer height='double' className='m-hide ' />
			<DesignSection designData={page} />
			<Spacer height='double' />
			<Carousel carouselImages={page.carouselImages} />
			<Spacer height='double' />
			<Team team={page} />
			<Spacer height='double' />
			{page.displayOnHomepage ? <Updates updateInfo={page} /> : null}
			<Spacer height='double' />
			<Map />
			<Spacer height='double' />
			<VideoPlayer />
			<Spacer height='double' />
			<SplitSection splitSection={page} />
			<Spacer />
			<Gallery images={page.gallery} />
			<Spacer />
			<Magazine name={page.magazineName} image={page.magazineImage} />
			<Spacer />
			<ContactBanner contactInfo={contactInfo} contact ctaImage={page.ctaImage} />
			<Spacer className='m-hide' />
			<Footer contactInfo={contactInfo} info={page} />
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query pageCopy {
		page: datoCmsTapestryLandingPage {
			ctaImage {
				gatsbyImageData
			}
			caption
			mobileIntroductionImage {
				gatsbyImageData
			}
			designQuoteAttribution
			designQuote
			designImageCaption
			carouselImages {
				gatsbyImageData
				url
			}
			gallery {
				gatsbyImageData
			}
			designImage {
				gatsbyImageData
			}
			introductionText
			introductionImage {
				gatsbyImageData
			}
			magazineName
			magazineImage {
				gatsbyImageData
			}
			designSubtitle
			splitSectionParagraph
			splitSectionImage {
				gatsbyImageData
			}
			splitSectionImageCaption
			splitSectionSubtitle
			teamTitle
			teamQuote
			teamImage {
				gatsbyImageData
			}
			teamImageCaption
			teamMembers {
				name
				memberPosition
				image {
					gatsbyImageData
				}
				id
			}
			displayOnHomepage
			updatesTitle
			updatesQuote
			updatesVideo
			updatesVideoThumbnail {
				gatsbyImageData
			}
			updates {
				id
				image {
					gatsbyImageData
				}
				caption
			}
			address
			email
			instagram
			facebook
			people {
				email
				name
				phone
			}
		}
	}
`
