import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import React from "react"
import TextSpacer from "./textSpacer"
import useAnimateInView from "../lib/useAnimateInView"
import Spacer from "./spacer"
import { MultiColumnCarousel } from "./carousel"

const Team = ({ team }) => {
	const { teamTitle, teamQuote, teamImage, teamImageCaption, teamMembers } = team
	const text = useAnimateInView()
	const image = useAnimateInView()
	return (
		<div id='team'>
			<div className='max-1200 ma flex i-flex-col m-flex-col p40'>
				<div className='w-50 pr40 flex inview  ' ref={text}>
					<div className='   '>
						<p className='h3 m0 m-mb40 mb20'>OUR TEAM</p>
						<Spacer />
						<h5 className='max-200'>{teamTitle}</h5>
						<TextSpacer />
						<div className='pb40 max-400 m-mb40 op65'>{teamQuote}</div>
					</div>
				</div>
				<div className='w-50   i-w-100' ref={image}>
					<Spacer className='i-show' />
					<div className='ratio-1-1 bg-grey'>
						<div className='bg-image'>
							<GatsbyImage className='bg-image' image={teamImage.gatsbyImageData} alt='Property Image' />
						</div>
					</div>
					<p className='image-caption  mt10'>{teamImageCaption}</p>
				</div>
			</div>
			<div className='mt40'>
				<MultiColumnCarousel carouselItems={teamMembers} />
			</div>
		</div>
	)
}

export default Team
