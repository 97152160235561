
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'



export default function Gallery({ images}) {
    return (
        <div className="p40  flex flex-col m-h-100 ">
            <div className="max-1200 ma flex m-flex-col h-100 flex-grow-1 w-100  ">
                <div className='gallery-grid'>
                    {images?.map((image) =>{
                        return <GatsbyImage image={image?.gatsbyImageData}/>
                    })}
                </div>
            </div>
        </div>

    )
}
