import React, { useEffect, useState } from "react"
import useAnimateInView from "../lib/useAnimateInView"
import Slider from "./Slider"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"

const Carousel = ({ carouselImages }) => {
	const section = useAnimateInView()
	return (
		<div className='p40 inview' ref={section}>
			<div className='max-1200 ma'>
				<div className=''>
					<Slider caption='Artist impression only. Correct at the time of production. Subject to change.' images={carouselImages.map(image => image.url)} />
				</div>
			</div>
		</div>
	)
}

export default Carousel

const MultiColumnCarousel = ({ team = true, carouselItems }) => {
	const [distanceToMoveCarousel, setDistanceToMoveCarousel] = useState(0)

	useEffect(() => {
		function calculateDistanceToMove() {
			const container = document.querySelector("#team").querySelector(".max-1200.ma")
			setDistanceToMoveCarousel(Math.round(container.getBoundingClientRect().left) + parseInt(window.getComputedStyle(container, null).getPropertyValue("padding-left")))
		}

		calculateDistanceToMove()

		window.addEventListener("resize", calculateDistanceToMove)
		return () => window.removeEventListener("resize", calculateDistanceToMove)
	}, [])

	const section = useAnimateInView()
	return (
		<div className='inview' ref={section}>
			<Swiper
				slidesPerView={"6"}
				breakpoints={{
					0: { slidesPerView: 2 },
					500: { slidesPerView: 3 },
					768: { slidesPerView: 4 },
					990: { slidesPerView: 6 },
				}}
				slidesOffsetBefore={distanceToMoveCarousel}
				slidesOffsetAfter={distanceToMoveCarousel}
				spaceBetween={20}
				modules={[Navigation]}
				navigation
				className='multiColumnCarousel flex'
			>
				{carouselItems.map(item => {
					return (
						<SwiperSlide key={item.id}>
							<div className=''>
								<div className='aspect-1-2 rel overflow'>
									<GatsbyImage className='bg-image' image={item.image.gatsbyImageData} alt='Property Image' />
								</div>
								<p className='image-caption mb0'>{team ? item.name : item.caption}</p>
								<p className='image-caption mt5'>{item.memberPosition}</p>
							</div>
						</SwiperSlide>
					)
				})}
				<div className='mask left' />
				<div className='mask right' />
			</Swiper>
		</div>
	)
}

export { MultiColumnCarousel }
