import { Swiper, SwiperSlide, } from "swiper/react";
import { Navigation, Pagination } from 'swiper';
import 'swiper/css'
import React, { useEffect, useState } from "react";
import Spacer from '../components/spacer'


const Slider = ({ images,caption,   ...props }) => {

    
    const [progress,setProgress] = useState(0)

    useEffect(() =>{
        document.querySelectorAll('button[data-pag]').forEach((button) =>{
            const index =  button.dataset.index
            button.addEventListener('click',() => slideTo(index))
        })
    },[])

    const swiperOptions = {
        speed: 750,
        grabCursor: true,
        resistanceRatio: 0,
        resistance: false,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        watchSlidesProgress: true,
        pagination: {

            el: '.swiper-pagination',
            renderBullet: function (index, className) {
                return `<button data-pag data-index="${index}" class="dot caption swiper-pagination-bullet">${(index + 1 > 9 ? index + 1 : `0${index + 1}`)}</button>`
            },

        },
        modules: [Navigation, Pagination],

        onProgress: function (_, progress) {
       
            var swiper = this; 
            
             
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * 0.5;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          
        },
        onSlideChangeTransitionStart: function (swiper) {
          
            setProgress(swiper.progress)
        },

        onSetTranslate: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].querySelector(".slide-inner").style.transition = `0ms ease all`;
            }
        },
        onTransitionStart: function (speed) {

            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    `750ms ease all`;
            }
        },
    }

    const slideTo = (index) =>{
        document.querySelector('.swiper')?.swiper.slideTo(index,1000,true,);
    }

    const slideNext = () => {
        document.querySelector('.swiper')?.swiper.slideNext();
       
    }

    const slidePrev = () => {
        document.querySelector('.swiper')?.swiper.slidePrev();
    }

    return (
        <>
            <div className='ratio-1-2 bg-grey reverse'>
                <div className="pt55p overflow-hidden   ma swiper-container ">
                    <Swiper className="bg-image "  {...swiperOptions} {...props} >
                        {images.map((image, index) => (
                            <SwiperSlide className="swiper-slide" key={index} >
                                <div style={{ backgroundImage: `url(${image})` }} className='slide-inner'></div>
                            </SwiperSlide>
                        ))}
                        <div className='swiper-pagination m-hide ' />
                        <div className='swiper-next m-hide '>
                            <svg width="15" height="20.625" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64645L7.17157 0.464465C6.97631 0.269203 6.65973 0.269203 6.46447 0.464466C6.2692 0.659728 6.2692 0.97631 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.7308 6.97631 7.7308 7.17157 7.53553L10.3536 4.35355ZM4.37114e-08 4.5L10 4.5L10 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z" fill="white" />
                            </svg>

                        </div>
                        <div className='swiper-prev m-hide '>
                            <svg width="15" height="20.625" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659729 4.53553 0.464467C4.34027 0.269204 4.02369 0.269205 3.82843 0.464467L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z" fill="white" />
                            </svg>

                        </div>
                    </Swiper>

                </div>
            </div>
            <p className="image-caption ">{caption}</p>
            <Spacer className='m-show m-small-spacer'/>
            <div className='flex rel m-show mobile--controls'>
                <button className='swiper-prev ' onClick={() => { slidePrev() }}>
                    <svg width="15" height="20.625" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659729 4.53553 0.464467C4.34027 0.269204 4.02369 0.269205 3.82843 0.464467L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z" fill="white" />
                    </svg>

                </button>
                <div className=" flex-grow-1 ma">
                    <p className='text-center'>
                        {`${(progress * (images.length - 1)) + 1}/${images.length}`}
                    </p>
                </div>
                <button className='swiper-next ' onClick={() => { slideNext() }}>
                    <svg width="15" height="20.625" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64645L7.17157 0.464465C6.97631 0.269203 6.65973 0.269203 6.46447 0.464466C6.2692 0.659728 6.2692 0.97631 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.7308 6.97631 7.7308 7.17157 7.53553L10.3536 4.35355ZM4.37114e-08 4.5L10 4.5L10 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z" fill="white" />
                    </svg>

                </button>

            </div>
        </>

    )
}

export default Slider