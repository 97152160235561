import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import logo from '../images/logo.svg'

function Arrow({ ball = true }) {
    return (
        <div className='flex flex-col align-center arrow'>
            <div>
            {ball ? <div className='ball' /> : null}
            </div>
            <div className='line' />
        </div>

    )
}

function Pointer({ name, left, bottom, height, maxWidth, children }) {
    return (
        
        <div style={{ left, bottom, height }} className='ab flex flex-col map-pointer  '>
            {!children ? <p className='label-map   label-top ' style={{ maxWidth }}>{name}</p> : children}
            
            <Arrow ball={!children} />
        </div>)
}

function LowerPointer({ name, left, top, height, maxWidth,children }) {
    return (
        <div style={{ left, top, height }} className='ab flex flex-col reverse   map-pointer '>
            <p className='label-map   ' style={{ maxWidth }}>{name}</p>
            <Arrow  ball={!children}/>
        </div>)
}

export default function Map() {
    return (
        <div className='map-outer-wrapper'>
              <div className='map-prompt h3'>
            SWIPE LEFT & RIGHT
            </div>
        <div className='rel map-wrapper   '>
          
            <div className='inner-wrapper'>
            <StaticImage objectPosition='center center' src='../images/map.jpg' className='' />
            <div className='bg-image  '>
                <Pointer left='7.5%' bottom='62%' height='25%' name='Surfers Paradise Beachfront' />
                <Pointer left='12%' bottom='71%' height='15%' name='CHEVRON RENAISSANCE SHOPPING CENTRE' />
                <Pointer left='20.2%' bottom='81%' height='8%' name='Surfers Pavillion' />
                <Pointer left='26.7%' bottom='73.5%' height='11%' name='Broadbeach' />
                <Pointer  maxWidth='50px' left='38%' bottom='68%' height='15%' name='ISLE Of  CAPRI' />
                <Pointer maxWidth='70px' left='41%' bottom='72%' height='15%' name='The Star Casino' />
                <Pointer left='43.75%' bottom='70%' height='22%' name='PACIFIC FAIR' />
                <Pointer left='75%' bottom='55%' height='17%' name='CHEVRON ISLAND MAINSTREET' />
                <Pointer maxWidth='84px' left='44.5%' bottom='64.1%' height='17%' >
                    <img className='mb10' width="84" src={logo} alt="Tapestry Logo" />
                </Pointer>

                <Pointer left='80.5%' maxWidth='100px' bottom='60%' height='15%' name='THE GREEN BRIDGE' />
                <LowerPointer height='12%' top='70%' left='8%' name='Budds Beach' />
                <Pointer left='85.6%' maxWidth='100px' bottom='67%' height='17%' name='HOME OF THE ARTS' />

            </div>
            </div>


            </div>        
        </div>
    )
}
