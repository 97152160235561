import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const useAnimateInView = () => {
    const { ref, inView, entry } = useInView({threshold:0.75});

    useEffect(() => {
        if (inView && entry) { 
            entry.target.style.opacity = 1
        }
    }, [inView, entry?.target.style,entry])


    return ref
}

export default useAnimateInView