import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import useAnimateInView from "../lib/useAnimateInView"

const DesignSection = ({ designData }) => {
	const { designQuoteAttribution, designQuote, designImage, designSubtitle, designImageCaption } = designData

	const image = useAnimateInView()
	const text = useAnimateInView()

	return (
		<div className='p40' id='design'>
			<div className='max-1200 ma flex m-flex-col'>
				<div className='w-50 pr40 inview' ref={image}>
					<p className='h3 m0 m-show m-mb40'>Design</p>
					<div className='ratio-2-1 bg-grey'>
						<div className='bg-image'>
							<GatsbyImage className='bg-image' image={designImage.gatsbyImageData} alt='Property Image' />
						</div>
					</div>
					<p className='image-caption mt10'>{designImageCaption}</p>
				</div>
				<div className='w-50 pl40 flex align-center inview ' ref={text}>
					<div>
						<p className='h3 m0 m-hide'>Design</p>
						<h5 className='m0 mt40 mb40'>{designSubtitle}</h5>
						<div className='pb40 max-400 op65  '>{designQuote}</div>
						<div className='op65 italic'>{designQuoteAttribution}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DesignSection
