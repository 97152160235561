import * as React from "react"
import Link from "./transitionLink"
import { GatsbyImage } from 'gatsby-plugin-image'

const Magazine = ({image,name}) => (
  <div className="">
    <div className="max-1200 ma ">
    
      <div className="flex  bg-light p40 m-flex-col">
        <div className="w-50">
          <div className="max-200 ma m-mt40 m-pb40 m-mb40">
            <div className="ratio-2-1 bg-green">
              <div className='bg-image'>
                <GatsbyImage image={image.gatsbyImageData} className='bg-image' alt="Magazine image"/>
              </div>
            </div>
          </div>
        </div>
        <div className="w-50 flex align-center m-pb40">
          <div className="cta-information ma m-flex m-flex-col m-flex-cen">
            <p className="m0 h3 m-center">LOCAL EVENTS AND UPDATES</p>
            <p className="m0 mt10 h1 mb40 m-center">{name}</p>
            <Link className="button xl m-center" to='/magazine'>Read More</Link>
          </div>
        </div>
       
      </div>
    </div>
  </div>
)

export default Magazine
