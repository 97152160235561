import React, {  useState } from "react"


const VideoPlayer = () => {
  const [playing, setPlaying] = useState(false)

  function play() {

    const media = document.querySelector('video')
    console.log(media.paused)
    if (media.paused && !playing) {
      media.play()
    }




  }




  return (


    <div  className="p40" id='art' >
      <div className="max-1200 ma">
        <p className="h3 m0 m-mb40 mb20">VIDEO</p>
        <div className="ratio-1-2 bg-light">



          <video loop onPause={() => { setPlaying(false) }} onPlay={() => { setPlaying(true) }} controls={(playing ? true : false)} autoPlay={true} playsInline={true} type='video/mp4' className={'bg-image cover z-1 '}>
            <source src='https://player.vimeo.com/progressive_redirect/playback/812230231/rendition/1080p/file.mp4?loc=external&signature=560d588f66867f5085ba6ddedd78dc7c7e424e84dd21b5e9ec3695f6e160c12b' />
            <track kind="captions"></track> 
          </video>
          {!playing ? <button className={'bg-image video    ' + (playing ? 'playing' : '')} onClick={play}  >
            <div className={'ma z-2 rel play'}>
              {!playing ? 'PLAY VIDEO' : ''}
            </div>
          </button> : null}
        </div>
      </div>
    </div>)
}

export default VideoPlayer
