import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Spacer from "./spacer"

const ScrollingBanner = ({ title, image, content, position, mobileImage, caption }) => (
	<>
		<div className='scrolling--banner h-100vh background--container'>
			<GatsbyImage className='bg-image fade-out m-hide' image={image?.gatsbyImageData} alt='Tapestry Hero Image' />
			<GatsbyImage className='bg-image fade-out m-show' image={mobileImage?.gatsbyImageData} alt='Tapestry Hero Image' />
			<p className='h1 fade-out banner--caption'>{caption}</p>
		</div>
		<div className='h-100vh scrolling--banner-text'>
			<div className={"sticky-title " + position}>
				<div className='p40 max-1400 ma banner--title text-white color--transition'>
					<p className='m0 h1' dangerouslySetInnerHTML={{ __html: title }}></p>
					<div className='content max-300 mt40'>{content}</div>
				</div>
			</div>
		</div>
		<Spacer className='m-hide' />
		<div className='title--block rel'>
			<div className='flex m-pt150 m-pb150 m-h-100 p40 '>
				<div className='max-400 ma text-center'>
					<h1 className='m0 mb40'>
						Where art
						<br /> and architecture converge
					</h1>
					<p className='m0 op-65'>Embodying the art and lifestyle of this unique location, this 22 level address presents an inspired collection of spacious two and three-bedroom designer apartments and unrivalled lifestyle amenities.</p>
					<p className='m0 h5 base mt80'>Chevron Island - Surfers Paradise</p>
				</div>
			</div>
		</div>
	</>
)

export default ScrollingBanner
