import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import useAnimateInView from "../lib/useAnimateInView"
import { MultiColumnCarousel } from "./carousel"

const Updates = ({ updateInfo }) => {
	const { updatesTitle, updatesQuote, updatesVideo, updatesVideoThumbnail, updates } = updateInfo
	const text = useAnimateInView()
	const image = useAnimateInView()

	return (
		<div id='updates'>
			<div className='max-1200 ma flex m-flex-col p40'>
				<div className='w-50 pr40 inview' ref={image}>
					<p className='h3 m0 m-show m-mb40'>LATEST UPDATE</p>
					<div className='ratio-1-1 bg-grey'>
						<div className='bg-image'>
							<GatsbyImage image={updatesVideoThumbnail.gatsbyImageData} className='bg-image dim' />
							<a href={updatesVideo} target='_blank' rel='noopener noreferrer' className='link'>
								<button className={"bg-image video"}>
									<div className={"ma z-2 rel play"}>PLAY VIDEO</div>
								</button>
							</a>
						</div>
					</div>
				</div>
				<div className='w-50 pl40 flex align-center inview ' ref={text}>
					<div>
						<p className='h3 m0 m-hide'>LATEST UPDATE</p>
						<h5 className='m0 mt40 mb40'>{updatesTitle}</h5>
						<div className='pb40 max-400 op65'>{updatesQuote}</div>
					</div>
				</div>
			</div>
			<div className='mt40'>
				<MultiColumnCarousel carouselItems={updates} team={false} />
			</div>
		</div>
	)
}

export default Updates
