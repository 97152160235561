import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Spacer from "./spacer"
import useAnimateInView from "../lib/useAnimateInView"

const Carousel = ({ ctaImage }) => {
  const section = useAnimateInView()
  return (<div ref={section} className="p40 m-p0">
    <div className="max-1200 ma ">
      <div className="ratio-1-2 bg-grey reverse ">
        <CTA ctaImage={ctaImage} />
      </div>
    </div>
  </div>
  )
}

const openMenu = (e) => {
  document.querySelector('.enquire--panel').classList.add('is-open');
  document.querySelector('.enquire--background').classList.add('is-open');
}

function CTA({ ctaImage }) {

  const [scaled, setScaled] = React.useState(false)
  return (
    <button onClick={openMenu} className='bg-image flex' onFocus={() => setScaled(true)} onMouseLeave={() => setScaled(false)} onMouseOver={() => setScaled(true)}>
      <div className={'cta--wrapper ' + (scaled ? ' scale' : '')}>
        <div className={'cta--container z-2 '}>
          <p className='pb40 m-pb20 p m0 h3 left'>Want more Information?</p>
          <h1 className='m-left m-pb20'>Register your interest </h1>
          <div className="m-hide">
            <Spacer />
          </div>
          <div className='flex'>
            <p className='button large white'>Enquire Now</p>
          </div>
        </div>
        <div className={'bg-image z-1   ' + (scaled ? ' scale' : '')}>
          <GatsbyImage className='bg-image' image={ctaImage.gatsbyImageData} alt="CTA Image" />
        </div>
      </div>
    </button>

  )
}

export default Carousel
